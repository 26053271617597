<template>
	<commonTitle v-bind:datas="datas"></commonTitle>
  <div>
    <div class="clearfix ">
      <div class="container  card-one">
        <div class="fl cdo-left animated"
             data-animation="fadeInLeft"
             animation-status="true"
             style="
            visibility: visible;
            animation-name: fadeInLeft;
            animation-delay: 0s;
          ">
          <!-- <div class="cdol-top">
    		  <header class="mgt60">
    		    <p class="font_24 center font_b core-title animated"
    		       data-animation="fadeInUp"
    		       animation-status="true">
    		      产品中心
    		    </p>
    			<p class="font_16 center mgt20 cdol-desc">
    			  软硬件产品自主研发，高效稳定。
    			</p>
    			<br />
    		  </header>
            
          </div> -->
        </div>
        <div class="cdo-right animated"
             data-animation="fadeInRight"
             animation-status="true"
             style="
            visibility: visible;
            animation-name: fadeInRight;
            animation-delay: 0s;
          ">
        </div>
      </div>
    </div>
	<!-- 应用案例 -->
    <div ref="scrollFour"
         class="core clearfix pab60 qiandao animated slow hide"
         :class="{ 'show fadeIn': showFour}">
      <header class="mgt60">
        <p class="font_24 center font_b core-title animated"
           data-animation="fadeInUp"
           animation-status="true">
          公司项目
        </p>
		<p style="width:50px;height:3px;margin:10px auto;padding:0px;background-color:#268A42;overflow:hidden;"></p>
		<br />
		<p class="font_16 center mgt20 cdol-desc">
		  项目分布广泛，深受客户青睐
		</p>
		<br />
      </header>
      <div class="container mgt40">
        <div class="clearfix core-content">
          <div class="cor-box animated slow hide" 
          :class="{ 'show fadeInRight': showTwo }">
            <!-- <div class="ab-left">
              <div class="box-img">
                <img src="@/assets/images/sysbusiness/icon_zhibo.png"
                     alt="" />
              </div>
            </div> -->
            <div class="ab-right">
              <div class="box-title">
                <p>美国光储项目</p>
              </div>
			  <div >
			      <img src="@/assets/images/sysbusiness/meiguoguangchu.png"
			           alt="" />
			  </div>
              <div class="box-desc">
                <span>项目地址: 美国，南加州<br/></span>
				<span>项目容量: 500kW/1MWh<br/></span>
				<span>项目应用: 光储结合<br/></span>
				<span>时间： 2017年</span>
              </div>
            </div>
			
		  </div>
          <div class="cor-box animated slow hide" 
          :class="{ 'show fadeInLeft': showTwo }">
            <!-- <div class="ab-left">
              <div class="box-img">
                <img src="@/assets/images/sysbusiness/icon_zhibo.png"
                     alt="" />
              </div>
            </div> -->
            <div class="ab-right">
              <div class="box-title">
                <p>美国电网调频项目</p>
              </div>
              <div >
                  <img src="@/assets/images/sysbusiness/meiguodianwangtiaopin2019.png"
                       alt="" />
              </div>
              <div class="box-desc">
                <span>项目地址: 美国，西弗吉尼亚州<br/></span>
              				<span>项目容量: 72MW/72MWh<br/></span>
              				<span>项目应用: 电网调频<br/></span>
              				<span>时间： 2019年</span>
              </div>
            </div>
          </div>
          
		  
		  <div class="cor-box animated slow hide"
		  :class="{ 'show fadeInRight': showTwo }">
		    <div class="ab-right">
		      <div class="box-title">
		        <p>加拿大全网调节(Global Adjustment )项目</p>
		      </div>
		  			  <div >
		  			      <img src="@/assets/images/sysbusiness/jianadadianwangtiaopin2021.png"
		  			           alt="" />
		  			  </div>
		      <div class="box-desc">
		        <span>项目地址: 加拿大，安大略省<br/></span>
		  				<span>项目容量: 11MW/32MWh <br/></span>
		  				<span>项目应用: 需求响应<br/></span>
		  				<span>时间： 2021年</span>
		      </div>
		    </div>
		  			
		  </div>
		  <div class="cor-box animated slow hide" 
		  :class="{ 'show fadeInLeft': showTwo }">
		    <!-- <div class="ab-left">
		      <div class="box-img">
		        <img src="@/assets/images/sysbusiness/icon_zhibo.png"
		             alt="" />
		      </div>
		    </div> -->
		    <div class="ab-right">
		      <div class="box-title">
		        <p>美国电网调频项目</p>
		      </div>
		      <div >
		          <img src="@/assets/images/sysbusiness/meiguodianwangtiaopin.png"
		               alt="" />
		      </div>
		      <div class="box-desc">
		        <span>项目地址: 美国，弗吉尼亚州<br/></span>
		      				<span>项目容量: 10.5MW/25MWh <br/></span>
		      				<span>项目应用: 调频、需求响应<br/></span>
		      				<span>时间： 2022年</span>
		      </div>
		    </div>	
		  </div>
		  
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonTitle from '@/components/common/commonTitle.vue'
export default {
  name: 'Project',
    components: {
        commonTitle,
    },
  data() {
    return {
	  datas:["project"],
      proList: [
        {
          name: '功能齐全',
          icon: require('@/assets/images/sysindex/icon_box.png'),
          desc1: '会前、签到、现场一站式管理',
          desc2: '支持全流程管理功能',
        },
        {
          name: '简单易用',
          icon: require('@/assets/images/sysindex/icon_easy.png'),
          desc1: '轻松上手',
          desc2: '10分钟搭建会议活动',
        },
        {
          name: '系统稳定',
          icon: require('@/assets/images/sysindex/icon_stable.png'),
          desc1: '百万场活动实践应用',
          desc2: '系统稳定、服务保障',
        },
        {
          name: '多端会务',
          icon: require('@/assets/images/sysindex/icon_cross.png'),
          desc1: '支持电脑端、小程序、H5等',
          desc2: '随时随地即可参会',
        },
        {
          name: '自定义网站',
          icon: require('@/assets/images/sysindex/icon_expand.png'),
          desc1: '多套模板供选择，网站域名、元素',
          desc2: '每一场会议都与众不同',
        },
        {
          name: '快速分享',
          icon: require('@/assets/images/sysindex/icon_share.png'),
          desc1: '分享内容个性化设置、一键分享',
          desc2: '提高会议活动的曝光率',
        },
      ],
      hezuoList: [
        {
          name: '系统销售',
          icon: require('@/assets/images/index/hezuo1.png'),
          desc1:
            '提供系统的源码、文档、脚本、使用培训、代码及技术架构培训及扩展开发指导。',
        },
        {
          name: '系统使用',
          icon: require('@/assets/images/index/zy.png'),
          desc1:
            '按年或按月收费，为企业、机构部署系统本地版，提供系统的使用培训。',
        },
        {
          name: '解决方案',
          icon: require('@/assets/images/index/fa.png'),
          desc1:
            '根据企业需求提供线上会务解决方案，包括系统架构方案设计及定制化功能开发。',
        },
      ],
      linyuList: [
        {
          name: '会展/品宣',
          icon: require('@/assets/images/sysindex/hangye/icon_first.png'),
          bg: require('@/assets/images/hezuo-1.png'),
          desc1: 'MEETING / PROMOTION',
        },
        {
          name: '峰会/论坛',
          icon: require('@/assets/images/sysindex/hangye/icon_second.png'),
          bg: require('@/assets/images/hezuo-2.png'),
          desc1: 'SUMMIT / FORUM',
        },
        {
          name: '培训/教育',
          icon: require('@/assets/images/sysindex/hangye/icon_third.png'),
          bg: require('@/assets/images/hezuo-3.png'),
          desc1: 'TRAINING / EDUCATION',
        },
        {
          name: '年会/庆典',
          icon: require('@/assets/images/sysindex/hangye/icon_fourth.png'),
          bg: require('@/assets/images/hezuo-4.png'),
          desc1: 'MEETING / CELEBRATION',
        },
      ],
      showOne: false,
      showTwo: true,
      showThree: false,
      showFour: true,
    }
  },
  methods: {
    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      // let oneHeight = this.$refs.scrollOne.offsetHeight
      // let twoHeight = this.$refs.scrollTwo.offsetHeight + oneHeight
      // let threeHeight = this.$refs.scrollThree.offsetHeight + twoHeight
        let fourHeight = this.$refs.scrollFour.offsetHeight
      // if (scrollTop > oneHeight) {
      //   this.showOne = true
      // }
      // if (scrollTop > twoHeight) {
      //   this.showTwo = true
      // }
      // if (scrollTop > threeHeight) {
      //   this.showThree = true
      // }
	if (scrollTop > fourHeight) {
	  this.showFour = true;
	}
    },
  },
  mounted() {
	window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
<style lang="scss">
@import '@/assets/css/business.scss';
</style>
